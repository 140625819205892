<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-button-toolbar
          class="demo-inline-spacing"
        >
          <!-- group 2 -->
          <b-button-group
            size="sm"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="pokupiListu(true)"
            >
              {{ $t("SviKandidati") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="pokupiListu()"
            >
              {{ $t('SamoMoji') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-warning"
              @click="listaKandidatiIsticuDatumi()"
            >
              {{ $t('isticuDatumi') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              @click="listaKandidatiIstekliDatumi()"
            >
              {{ $t('istekliDatumi') }}
            </b-button>
          </b-button-group>
        </b-button-toolbar>
      </b-card-body>
    </b-card>
    <b-card>
      <b-card-body :title="$t('Kandidati')">
        <b-table
          v-if="Object.entries(items).length"
          :items="items"
          :fields="fields"
          striped
          responsive
          class="mb-0"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template #cell(detalji)="row">
            <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
            <b-form-checkbox
              v-model="row.detailsShowing"
              @change="row.toggleDetails"
            >
              {{ row.detailsShowing ? $t('Sakrij') : $t('Prikazi') }}
            </b-form-checkbox>
          </template>
          <template #cell(id)="data">
            <b-button-group
              size="sm"
            >
              <router-link
                :to="{
                  name: 'instruktori-kandidati-prikazi',
                  params: { id: data.item.id },
                }"
              >
                <b-button
                  variant="info"
                  size="sm"
                >
                  {{ $t('Pogledaj') }}
                </b-button>
              </router-link>
            </b-button-group>
          </template>
          <template #cell(obavestenja)="data">
            <b-badge
              v-if="data.item.pristizu_datumi"
              variant="warning"
              pill
              class="badge-round"
            >
              P
            </b-badge>
            <b-badge
              v-if="data.item.istekli_datumi"
              variant="danger"
              pill
              class="badge-round"
            >
              I
            </b-badge>
          </template>

          <!-- full detail on click -->
          <template #row-details="row">
            <b-card>
              <b-table-simple
                small
                responsive
              >
                <b-tbody>
                  <b-tr
                    :variant="row.item.potrebna_pomagala_prikaz === 'Potrebna' ? 'danger' : 'success'"
                  >
                    <b-th
                      class="col-3"
                    >
                      {{ $t('PotrebnaPomagala') }}
                    </b-th>
                    <b-td>{{ row.item.potrebna_pomagala_prikaz }}</b-td>
                  </b-tr>
                  <b-tr
                    :variant="row.item.status_teorijskog_ispita === 'istekao' ? 'danger' : row.item.status_teorijskog_ispita === 'istice' ? 'warning' : 'success'"
                  >
                    <b-th
                      class="col-3"
                    >
                      {{ $t('TeorijskiIspit') }}
                    </b-th>
                    <b-td>{{ row.item.datum_vazenja_ti_prikaz }}</b-td>
                  </b-tr>
                  <b-tr
                    :variant="row.item.vazi_lekarsko === false ? 'danger' : row.item.istice_lekarsko === true ? 'warning' : 'success'"
                  >
                    <b-th
                      class="col-3"
                    >
                      {{ $t('Lekarsko') }}
                    </b-th>
                    <b-td>{{ row.item.datum_vazenja_lekarskog }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th
                      class="col-3"
                    >
                      {{ $t('OstaloCasovaPrakticneObuke') }}
                    </b-th>
                    <b-td>10</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <b-button
                size="sm"
                variant="outline-secondary"
                @click="row.toggleDetails"
              >
                {{ $t('SakrijDetalje') }}
              </b-button>
            </b-card>
          </template>

          <template #cell(status)="data">
            <b-badge
              :variant="status[1][data.value]"
            >
              {{ status[0][data.value] }}
            </b-badge>
          </template>
        </b-table>
        <b-alert
          v-else
          variant="primary"
          show
        >
          <div
            class="alert-body"
            style="padding: 30px;"
          >
            <span>{{ $t('NemaRezultata') }}</span>
          </div>
        </b-alert>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  BFormCheckbox,
  BButton,
  BCardBody,
  BBadge,
  BButtonGroup,
  BCard,
  BPagination,
  BButtonToolbar,
  BTableSimple,
  BTbody,
  BTd,
  BTh,
  BTr,
  BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BTable,
    BButton,
    BButtonToolbar,
    BFormCheckbox,
    BCardBody,
    BBadge,
    BButtonGroup,
    BCard,
    BPagination,
    BTableSimple,
    BTbody,
    BTd,
    BTh,
    BTr,
    BAlert,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perPage: 20,
      pageOptions: [20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      fields: [
        { key: 'detalji', label: this.$i18n.t('Detalji') },
        { key: 'idkandidata', label: this.$i18n.t('IdKandidata') },
        { key: 'ime', label: this.$i18n.t('Ime') },
        { key: 'prezime', label: this.$i18n.t('Prezime') },
        { key: 'telefon', label: this.$i18n.t('Telefon') },
        { key: 'status', label: this.$i18n.t('status') },
        { key: 'obavestenja', label: this.$i18n.t('Obavestenja') },
        { key: 'id', label: 'Akcije' },
      ],
      /* eslint-disable global-require */
      items: [],
      status: [
        {
          aktivan: this.$i18n.t('Aktivan'),
          neaktivan: this.$i18n.t('Neaktivan'),
          probni: this.$i18n.t('Probni'),
        },
        {
          aktivan: 'light-success',
          neaktivan: 'light-danger',
          probni: 'light-warning',
        },
      ],
    }
  },
  mounted() {
    this.pokupiListu()
  },
  methods: {
    pokupiListu(sviKandidati = false) {
      const url = sviKandidati
        ? '/instruktori/kandidati/sve'
        : '/instruktori/kandidati'
      this.$http.get(url).then(response => {
        this.items = response.data
      })
    },
    listaKandidatiIsticuDatumi(sviKandidati = false) {
      const url = sviKandidati
        ? '/instruktori/kandidati/isticu-datumi/sve'
        : '/instruktori/kandidati/isticu-datumi'
      this.$http.get(url).then(response => {
        this.items = response.data
      })
    },
    listaKandidatiIstekliDatumi(sviKandidati = false) {
      const url = sviKandidati
        ? '/instruktori/kandidati/istekli-datumi/sve'
        : '/instruktori/kandidati/istekli-datumi'
      this.$http.get(url).then(response => {
        this.items = response.data
      })
    },
  },
}
</script>
